import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function Conect() {
  const connectRef = useRef(null); // connect div를 참조
  const location = useLocation(); // 현재 URL 정보 가져오기
  const navigate = useNavigate();
  const agreeCheck = useRef();
  const companyName = useRef();
  const userName = useRef();
  const emailName = useRef();
  const title = useRef();
  const number = useRef();
  const detail = useRef();

  useEffect(() => {
    const params = new URLSearchParams(location.search); // 쿼리 파라미터를 파싱
    if (params.get('connect') === 'true' && connectRef.current) {
      // connect 요소로 스크롤
      setTimeout(()=>{
        connectRef.current.scrollIntoView({
          behavior: 'smooth', // 부드럽게 스크롤
          block: 'center',    // 화면 가운데로 이동
          inline: 'nearest',  // 수평 스크롤 유지
        });      // 작업 완료 후 쿼리 파라미터 제거
        params.delete('connect'); // 쿼리 파라미터 제거
        const newPath = location.pathname; // 현재 경로를 가져옴
        navigate(`${newPath}`, { replace: true }); // 쿼리 파라미터 없이 리다이렉트

      },500)
    }
  }, [location]); // location 변경 시마다 실행



  // 문의 보내기
  function sendMail() {
    const fields = [
      { 
        ref: agreeCheck, 
        message: '약관에 동의해주세요', 
        check: () => !agreeCheck.current.checked // 체크 여부 확인
      },
      { 
        ref: companyName, 
        message: '회사명을 입력해주세요', 
        check: () => companyName.current.value === '' 
      },
      { 
        ref: userName, 
        message: '이름을 입력해주세요', 
        check: () => userName.current.value === '' 
      },
      { 
        ref: title, 
        message: '이메일을 입력해주세요', 
        check: () => emailName.current.value === '' 
      },
    ];
    
    // 각 필드 체크
    for (const field of fields) {
      if (field.check()) {
        alert(field.message);
        field.ref.current.focus(); // focus가 가능한 경우에만 호출
        return;
      }
    }

    // 필드들이 유효하면 이메일 보내기
    const userNameValue = userName.current.value;
    const emailValue = emailName.current.value;
    const companyValue = companyName.current.value;
    const titleValue = title.current.value;
    const detailValue = detail.current.value;
    const numberValue = number.current.value;

    const templateParams = {
      userName: userNameValue,
      email: emailValue,
      companyName: companyValue,
      title: titleValue,
      detail: detailValue,
      number: numberValue,
    };

    emailjs
      .send('service_zo101yh', 'template_m5uoru7', templateParams, 'HJzLDFQZ4bLWUeM2k')
      .then(
        () => {
          window.alert('SUCCESS!');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  }
  
  



  return (
    <div className='conect'>
      <div className='sendWrap'>
        <div className="infoWrap" ref={connectRef} id="connect">
          <div className="top">
            <p className='firstTxt'>최고의 데이터 파트너가 되겠습니다.</p>
            <p className='secondTxt'>(주)스페이셜코어는 데이터 관련 지원사업, 컨설팅,
데이터 구축 / 가공 / 분석 / 시각화 / 현행화 / 유통 등
데이터의 모든 것을 다룹니다.<br/>
궁금하신 부분, 함께하실 부분 편하게 문의주세요.</p>
          </div>
          <div className="bottom">
            <input type='checkbox' ref={agreeCheck}></input>
            <p>개인정보 수집 및 이용 동의<button type='button'>약관보기</button></p>
          </div>
        </div>
        <div className="inputWrap">
            <ul>
              <li>
                <p className="title">회사명<span className='redStar'>*</span></p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="company" name='companyName' placeholder="회사명을 입력해주세요." ref={companyName}></input>
                </p>
              </li>
              <li>
                <p className="title">Name<span className='redStar'>*</span></p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="name" name='userName' placeholder="이름을 입력해주세요." ref={userName}></input>
                </p>
              </li>
              <li>
                <p className="title">Email<span className='redStar'>*</span></p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="email" name='email' placeholder="이메일을 입력해주세요." ref={emailName}></input>
                </p>
              </li>
              <li>
                <p className="title">Phone number</p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="number" name='number' placeholder="전화번호를 입력해주세요." ref={number}></input>
                </p>
              </li>
              <li>
                <p className="title">Subject</p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="title" name='title' placeholder="제목을 입력해주세요." ref={title}></input>
                </p>
              </li>
              <li>
                <p className="title">Note</p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <textarea id="note" name='detail' placeholder="문의하고자 하는 내용을 입력해주세요." ref={detail}></textarea>
                </p>
              </li>
            </ul>
            <p className='sendBtn' onClick={sendMail}>Send Message</p>
        </div>
        </div>
      <div className='mapWrap'>
        <ul className='addressWrap'>
          <li>
            <p className='icon'>
              <img src={process.env.PUBLIC_URL + "/img/phoneicon.png"}></img>
            </p>
            <p className='adTxt'>
              전화번호
              <span>02-6339-2117</span>
            </p>
          </li>
          <li>
            <p className='icon'>
              <img src={process.env.PUBLIC_URL + "/img/mailicon.png"}></img>
            </p>
            <p className='adTxt'>
              이메일
              <span>master@spatialcore.ai</span>
            </p>
          </li>
          <li>
            <p className='icon'>
              <img src={process.env.PUBLIC_URL + "/img/mapicon.png"}></img>
            </p>
            <p className='adTxt'>
              주소
              <span>서울특별시 광진구 광나루로 478, 광진경제허브 도약관 406</span>
            </p>
          </li>
        </ul>
        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.429812476849!2d127.07718208850194!3d37.544936153296156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca4d8638bbf7f%3A0x2afc163e219c8c60!2z7ISc7Jq47Yq567OE7IucIOq0keynhOq1rCDqtJHrgpjro6jroZwgNDc4!5e0!3m2!1sko!2skr!4v1728626671511!5m2!1sko!2skr"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Conect;