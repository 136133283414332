import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function OnDevice() {
  return (
    <>
      <div className='OnDeviceContainer'>
        <h3>On-Device Spatial AI</h3>
        <p>통신망이 끊겨도 공간을 인식해야 하는 스마트 디바이스, 자가 이동형 무인체, 자율주행 차량 등을 위한 Saas화 및 경향화 된 공간지능 AI 솔루션이자 (주)스페이셜코어의 글로벌 성장전략</p>
        <div className='imageWrap'>
            <img src={process.env.PUBLIC_URL + "/img/OnDevice.png"}></img>
        </div>
      </div>
    </>
  )
}

export default OnDevice;