import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Experience() {
  return (
    <>
      <div className='ExperienceContainer'>
        <h3>Experience</h3>
        <div className='cubeInfo'>
          <ul>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/Ex1.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>데이터바우처 지원사업</span>
                과학기술정보통신부 및 한국데이터산업진흥원 지원사업으로,
데이터 수요·공급 생태계 조성과 전 산업의 디지털 기반 데이터 사용 활성화를 위한 데이터 구매·가공 지원 사업
<br/>- 데이터 구매 / 가공 / AI데이터 가공 3개 분야
<br/>- 2018년 데이터바우처사업 시작부터 공급 및 가공 서비스를 다수의 기업에게 제공
<br/>- 다양한 분야의 예비창업부터 중소기업에 데이터 기반 사업화와 개발을 가이드
              </p>
            </li>
            <li>
              <div className="imagesCon">
                <img src={process.env.PUBLIC_URL + "/img/Process1.png"}></img>
                <div className='line'></div>
                <img src={process.env.PUBLIC_URL + "/img/Process2.png"}></img>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/Ex2.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>공공데이터 기업매칭 지원사업</span>
                행정안전부 및 한국지능정보사회인증원 지원사업으로,
다양한 수요기관의 목적에 맞는 데이터 구축과 품질정비 후 공공데이터로서 개방 진행
<br/>- 데이터 구축 / 품질정비 / Open API 개발 3개 분야
<br/>- 전국성 표준 데이터, 융합 데이터, 비정형 데이터
<br/>- 공공기관 DB표준화지침 준수, 공공데이터 표준화 적용 등 진행함
              </p>
            </li>
            {/* <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/Ex3.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>관광 데이터</span>
                애프터 코로나 시대를 준비하는 관광활성화의 
중심에도 데이터 기반 의사결정이 매우 중요합니다, 한국관광공사와 공공기관, 지자체 자문을 해오며 
스마트관광거리 데이터 설계, 한류데이터 구축 등 
다양한 인/아웃바운드 사업에서 고품질의 
데이터를 만들 수 있도록 노력합니다.
              </p>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Experience;