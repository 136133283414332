import { useState } from 'react';
import '../css/Footer.css'
import { Link } from 'react-router-dom';
import Main from './Main';

function Footer() {

  
  return (
    <footer>
      <p className='logo'><img src={process.env.PUBLIC_URL + "/img/logo2.png"}></img></p>
      <div>
        <p className='company'>스페이셜코어</p>
        <ul>
          <li><Link to={Main}>이용약관</Link></li>
          <li><Link to={Main}>개인정보처리방침</Link></li>
          <li><Link to={Main}>저작권정책</Link></li>
        </ul>
        <p className='address'>서울 광진구 광나루로 478, 광진경제허브센터 도약관 406호<br/>
master@spatialcore.ai<br/>
Copyright © 2023 by Spatial Core Inc.</p>
      </div>
    </footer>
  )
}

export default Footer ;