import '../css/Header.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Main from './Main';
import { useEffect, useRef, useState } from 'react';

function Header() {
  const [navActive, setNavActive] = useState(false); // 상태 관리
  const navRef = useRef(null);
  const msearchWrap = useRef(null);
  const headAnim = useRef();
  const moveY = useRef();
  const burgerCheck = useRef(); // burger-check에 접근하기 위한 ref 추가
  const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태
  const navigate = useNavigate(); // 페이지 이동을 위한 훅
  const location = useLocation();

  useEffect(() => {
    if(navRef.current.classList.contains('active')){
      navRef.current.classList.remove('active');
    }
    if (burgerCheck.current) {
      burgerCheck.current.checked = false;
    }

  }, [location]);



  const handleSearch = () => {
    if (searchQuery) {
      navigate(`/product?search=${searchQuery}`); // 쿼리 파라미터로 검색어 전달
    }
  };

  const handleContactClick = () => {
    navigate('/?connect=true'); // 랜딩 페이지로 이동하고 connect로 이동
  };

  const msearch = function(isActive) {
    if (isActive) {
      msearchWrap.current.classList.add('active');
    } else {
      msearchWrap.current.classList.remove('active');
    }
  }


  useEffect(() => {
    // navActive가 true일 때 nav에 active 클래스를 추가
    if (navActive) {
      navRef.current.classList.add('active');
    } else {
      navRef.current.classList.remove('active');
    }
    if(!navRef.current.classList.contains('active')){
      burgerCheck.current.checked = false;
    }
  }, [navActive]);
  

  return (
    <>
      <header>
        <h1><Link to={'/'}><img src={process.env.PUBLIC_URL + "/img/logo.png"}></img></Link></h1>
        <div className='rightWrap'>
          <div className="searchWrap">
            <input 
              type="text" 
              placeholder="키워드로 데이터를 검색해보세요."
              value={searchQuery} // 검색어 상태 연결
              onChange={(e) => setSearchQuery(e.target.value)} // 검색어 입력 시 상태 업데이트
            />
            <span>
              <img 
                className='searchBtn' 
                src={process.env.PUBLIC_URL + "/img/search.png"} 
                alt="search"
                onClick={handleSearch} // 검색 버튼 클릭 시 handleSearch 실행
              />
            </span>
          </div>
          <div className='mSearchBtn'>
            <img className='searchBtn' onClick={() => msearchWrap.current.classList.toggle('active')} src={process.env.PUBLIC_URL + "/img/search.png"} />
          </div>
          <div className='nav'>
              <input className="burger-check" type="checkbox" id="burger-check" ref={burgerCheck} />
              <label className="burger-icon" htmlFor="burger-check" onClick={() => setNavActive((prev) => !prev)}>
                  <span className="burger-sticks"></span>
              </label>
          </div>
        </div>
        <div className="mSearchWrap" ref={msearchWrap}>
          <input type="text" placeholder="키워드로 데이터를 검색해보세요." />
          <span>
            <img src={process.env.PUBLIC_URL + "/img/search.png"} />
          </span>
        </div>
      </header>
      <nav ref={navRef} 
            onMouseLeave={() => setNavActive(false)}>
        <ul className='navWrap'>
          <li>
            <p>
              <Link to={'Product'}>데이터 상품</Link>
            </p>
            {/* <ul className='navContents'>
              <li><Link to={Main}>전체 상품</Link></li>
              <li><Link to={Main}>무료 상품</Link></li>
              <li><Link to={Main}>유료 상품</Link></li>
            </ul> */}
          </li>
          <li>
            <p>
              <Link to={'Visual'}>데이터 분석</Link>
            </p>
            {/* <ul className='navContents'>
              <li><Link to={'Visual'}>시각화 분석</Link></li>
            </ul> */}
          </li>
          {/* <li>
            <p>
              <Link to={Main}>데이터 활용</Link>
            </p>
            <ul className='navContents'>
              <li><Link to={Main}>분석 사례</Link></li>
              <li><Link to={Main}>활용 사례</Link></li>
            </ul>
          </li> */}
          <li>
            <p>
              <a onClick={handleContactClick}>문의하기</a>
            </p>
            {/* <ul className='navContents'>
              <li><Link to={'notice'}>공지사항</Link></li>
              <li><Link to={'notice'}>FAQ</Link></li>
              <li><Link to={'notice'}>Q&A</Link></li>
            </ul> */}
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Header;
