import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Service() {
  return (
    <>
      <div className='ServiceContainer'>
        <div className='cubeInfo'>
          <ul>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service1.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>데이터 가공 서비스</span>
                <span className='color'>활용성 높은 데이터 생산</span>
                고객사 요청에 따른 커스터마이징 서비스 제공
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service2.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>이종데이터 융복합 서비스</span>
                <span className='color'>새로운 데이터 개발</span>
                다양한 도메인 데이터를 융합하여 분석에 활용
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service3.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>3D 매핑 서비스</span>
                <span className='color'>시각화 분석 솔루션</span>
                2D 지도 기반 데이터 융합으로 3D 시각화
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service4.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>AI 모델 상용화 서비스</span>
                <span className='color'>인공지능 모델 학습</span>
                AI 모델링 학습데이터 구축 및 sLLM 서비스 도입
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Service;