import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function BusinessModel() {
  return (
    <>
      <div className='businessModelContainer'>
        <h3>Business Model</h3>
        <img src={process.env.PUBLIC_URL + "/img/BusinessModel.png"}></img>
      </div>
    </>
  )
}

export default BusinessModel;