import '../css/Dataproduct.css';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Dataproduct() {

  // 각 카테고리에 대한 상태 선언
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const itemsPerPage = 12; // 페이지당 아이템 수
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeRegion, setActiveRegion] = useState(0);
  const [activeCondition, setActiveCondition] = useState(0);
  const [activeFormat, setActiveFormat] = useState(0);
  const [activeVisualType,setActiveVisualType] = useState(0);
  const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태
  const navigate = useNavigate(); // 페이지 이동을 위한 훅
  const location = useLocation(); // URL에서 쿼리 파라미터를 가져옴
  const queryParams = new URLSearchParams(location.search);

  // 공통적으로 사용할 클릭 핸들러
  const handleClick = (setStateFunction, index) => {
    setStateFunction(index);
  };


  const categories = ['전체', '경제,금융', '사회,과학', '산업', '주택,건설', '교통', '보건,의료', '사회복지', '교육', '환경,에너지', '문화,예술', '관광', '체육'];
  const regions = ['전국', '서울', '경기도', '강원도', '충청도', '전라도', '경상도'];
  const visualType = ['전체','지도', '원', '선', '막대', '3D 지도'];
  const conditions = ['전체', '유료', '무료'];
  const formats = ['전체', '정형 데이터', '비정형 데이터'];
  const structuredFormats = ['JSON', 'CSV', 'XLSX']; // 정형 데이터 포맷
  const unstructuredFormats = ['JPEG', 'MP4', 'PNG']; // 비정형 데이터 포맷

  // 컨텐츠 리스트
  const [activeSort, setActiveSort] = useState(0); // 정렬 옵션 상태
  const sortOptions = ['최신순', '다운로드순', '조회순'];

  const handleSortClick = (index) => setActiveSort(index);


  const categoryToVisualType = (category)=>{
    switch (category) {
        case 'map':
            return 1;
        case 'pie':
            return 2;
        case 'line':
            return 3;
        case 'bar':
            return 4;
        case '3D':
            return 5;
        
    }
  }
  const movePage = (id) => {
    if(activeVisualType&& activeVisualType !== 0){
        const visualTypeToCategory = ['null','map','pie','line','bar','3D'];
        navigate(`/graph?id=${id}&category=${visualTypeToCategory[activeVisualType]}`); // 이동할 경로 설정

    }else{
        navigate(`/graph?id=${id}`); // 이동할 경로 설정

    }
  }


  // 데이터 리스트 (예시 데이터)
  const dataItems = [
    {
        id : 1,
        title: '스페이셜코어',
        category: '건물,건축,관광',
        type: 'JSON, CSV',
        price: '무료',
        createdDate: '2024-09-05',
        region: '전국',
        description: '본 데이터세트는 다양한 건물과 장소에 대한 위치 정보 및 범주 정보를 제공하며, 도시 개발 및 관리, 위치 기반 서비스, 지도 제작 등에 활용됩니다.',
        imageUrl: 'search01',
        visualType: ['원', '선', '막대', '3D 지도']
    },
    {
        id : 1,
        title: '교통 네트워크 데이터',
        category: '교통, 산업',
        type: 'CSV, XLSX',
        price: '유료',
        createdDate: '2024-08-12',
        region: '서울',
        description: '이 데이터는 서울 지역의 교통 네트워크, 도로 정보 및 혼잡도를 포함하며 교통 계획 및 최적화에 적합합니다.',
        visualType: ['원', '선', ]
    },
    {
        id : 1,
        title: '지역별 인구통계',
        category: '사회,금융',
        type: 'JSON',
        price: '무료',
        createdDate: '2024-07-15',
        region: '경기도',
        description: '경기도 지역의 인구밀도와 연령 분포에 대한 데이터를 포함하여 사회 복지 및 정책 수립에 활용 가능합니다.',
        visualType: [ '선', '막대', '3D 지도']
    },
    {
        id : 1,
        title: '주택 가격 예측 데이터',
        category: '주택,건설',
        type: 'Parquet, CSV',
        price: '유료',
        createdDate: '2024-06-10',
        region: '전라도',
        description: '전라도 지역의 주택 가격 데이터로 부동산 시장 분석 및 가격 예측 모델에 사용됩니다.',
        visualType: ['지도', '원', '선']
    },
    {
        id : 1,
        title: '에너지 소비 데이터',
        category: '환경,에너지',
        type: 'CSV',
        price: '무료',
        createdDate: '2024-05-01',
        region: '전국',
        description: '이 데이터는 전국의 에너지 소비량 및 패턴을 포함하며 지속 가능한 에너지 정책 연구에 적합합니다.',
        visualType: ['지도', '원', ]
    },
    {
        id : 1,
        title: '관광지 방문객 데이터',
        category: '관광',
        type: 'JSON',
        price: '무료',
        createdDate: '2024-04-25',
        region: '충청도',
        description: '충청도 주요 관광지의 방문객 수와 계절별 데이터를 포함하여 관광 산업 분석에 활용됩니다.',
        visualType: ['지도', '3D 지도']
    },
    {
        id : 1,
        title: '문화예술 행사 데이터',
        category: '문화,예술',
        type: 'XLSX, JSON',
        price: '유료',
        createdDate: '2024-03-18',
        region: '강원도',
        description: '강원도 지역의 문화예술 행사 일정 및 참여 데이터를 제공하여 이벤트 기획 및 문화 산업 연구에 적합합니다.',
        visualType: ['지도', '원', '3D 지도']
    },
    {
        id : 1,
        title: '공공 시설 데이터',
        category: '사회복지, 공공',
        type: 'CSV',
        price: '무료',
        createdDate: '2024-02-10',
        region: '서울',
        description: '서울시 내 공공 시설 위치 및 운영 정보를 제공하여 도시 계획 및 복지 서비스에 활용됩니다.',
        visualType: ['지도', '원', '선', '막대', '3D 지도']
    },
    {
        id : 1,
        title: '교육 통계 데이터',
        category: '교육',
        type: 'JSON, XLSX',
        price: '유료',
        createdDate: '2024-01-20',
        region: '경상도',
        description: '경상도 지역의 교육기관 분포와 학생 수 데이터를 포함하여 교육 정책 연구에 적합합니다.',
        visualType: ['지도', '막대', '3D 지도']
    },
    {
        id : 1,
        title: '도로 네트워크 데이터',
        category: '교통, 건설',
        type: 'Parquet, JSON',
        price: '무료',
        createdDate: '2023-12-15',
        region: '전라도',
        description: '전라도 지역의 도로 네트워크 및 연결성을 분석할 수 있는 데이터로, 도시 인프라 개발에 활용됩니다.',
        visualType: [, '원', '선', ]
    },
    {
        id : 1,
        title: '헬스케어 데이터',
        category: '보건,의료',
        type: 'CSV',
        price: '무료',
        createdDate: '2023-11-05',
        region: '충청도',
        description: '충청도 내 의료 시설 및 환자 데이터를 포함하여 헬스케어 시스템 연구 및 개선에 적합합니다.',
        visualType: ['지도', '원', ]
    },
    {
        id : 1,
        title: '체육 시설 데이터',
        category: '체육',
        type: 'XLSX, CSV',
        price: '유료',
        createdDate: '2023-10-15',
        region: '서울',
        description: '서울 지역의 체육 시설 위치 및 이용 가능 정보를 제공하여 도시 체육 정책 연구에 활용됩니다.',
        visualType: ['지도', '원', '선', ]
    }
    ]

    // 필터링 로직
    const filteredItems = dataItems.filter((item) => {
        const categoryMatch = activeCategory === 0 || item.category.includes(categories[activeCategory]);
        const regionMatch = activeRegion === 0 || item.region === regions[activeRegion];
        const conditionMatch = activeCondition === 0 || item.price === conditions[activeCondition];
        const formatMatch =
            activeFormat === 0 ||
            (activeFormat === 1 && structuredFormats.some((format) => item.type.includes(format))) ||
            (activeFormat === 2 && unstructuredFormats.some((format) => item.type.includes(format)));
        const visualTypeMatch =
            activeVisualType === 0 || // 전체 선택 시 모든 항목 포함
            (item.visualType && item.visualType.includes(visualType[activeVisualType]));
        const searchMatch = searchQuery === '' || Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        return categoryMatch && regionMatch && conditionMatch && formatMatch && visualTypeMatch && searchMatch;
    });
    


    // 현재 페이지에 표시될 데이터
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 수 계산
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    // 페이지 이동 핸들러
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('search') || '';
        setSearchQuery(query);
        const visualTypeIdParam = queryParams.get('visualTypeId') || '0'; 
        setActiveVisualType(Number(visualTypeIdParam));
    }, [location.search]);


  return (
    <div id="product">
        <h2>데이터 상품</h2>
        <div className="actionWrap">
            <div className="searchWrap">
                <input
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // 검색어 상태 업데이트
                />
                <span className="searchIcon">
                    <img src={process.env.PUBLIC_URL + "/img/search43.png"} alt="검색" />
                </span>
            </div>
            <div className="categoryWrap">
                <p className="categoryTitle">데이터 마켓 상세검색</p>
                <ul className="categoryList">
                    {/* 카테고리 */}
                    <li>
                        <p className="listTitle">&middot; 카테고리</p>
                        <ul className="listContent">
                            {categories.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeCategory === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveCategory, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>

                    {/* 지역별 */}
                    <li>
                        <p className="listTitle">&middot; 지역별</p>
                        <ul className="listContent">
                            {regions.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeRegion === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveRegion, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>
                    {/* 시각화 유형 */}
                    <li>
                        <p className="listTitle">&middot; 시각화 유형</p>
                        <ul className="listContent">
                            {visualType.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeVisualType === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveVisualType, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>

                    {/* 이용조건 */}
                    <li>
                        <p className="listTitle">&middot; 이용조건</p>
                        <ul className="listContent">
                            {conditions.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeCondition === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveCondition, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>

                    {/* 파일포맷 */}
                    <li>
                        <p className="listTitle">&middot; 파일포맷</p>
                        <ul className="listContent">
                            {formats.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeFormat === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveFormat, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div className="displayWrap">
    <div className="cubeInfo">
        <ul>
            {currentItems.map((item, index) => (
                <li key={index} onClick={() => movePage(item.id)}>
                    <p className="infoImg">
                        <img src={`${process.env.PUBLIC_URL}/img/search${index+1}.png`} alt={item.title} />
                    </p>
                    <div className="textWrap">
                        <ul className="titleTxt">
                            <li>유형 | {item.type}</li>
                            <li>생성날짜 | {item.createdDate}</li>
                            <li>카테고리 | {item.category}</li>
                        </ul>
                        <p className="infoTxt">
                            내용 -<br />
                            <span className="ellipsis">{item.description}</span>
                        </p>
                    </div>
                </li>
            ))}
        </ul>
        {/* Pagination */}
        <div className="page">
            <button
                className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                &lt;
            </button>
            <ul className="pagination">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <li
                        key={page}
                        className={page === currentPage ? 'active' : ''}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </li>
                ))}
            </ul>
            <button
                className={`next ${currentPage === totalPages ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                &gt;
            </button>
        </div>
    </div>
</div>

    </div>
);
}

export default Dataproduct;
