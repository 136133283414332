import '../css/Main.css';
import '../css/Experience.css';
import '../css/Service.css';
import '../css/Conect.css';
import '../css/BusinessModel.css';
import '../css/OnDevice.css';
import Bnrbusiness from './Bnrbusiness.js';
import Experience from './Experience.js';
import Service from './Service.js';
import Conect from './Conect.js';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import BusinessModel from './BusinessModel.js';
import OnDevice from './OnDevice.js';

function Main() {
  return (
    <div id='main'>
      <Bnrbusiness/>
      <BusinessModel/>
      <OnDevice/>
      <Experience/>
      <Service/>
      <Conect/>
    </div>
  )
}

export default Main;