import '../css/Visualization.css';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Visualization() {
  const categories = [
    '경제', 
    '사회', 
    '과학', 
    '기술', 
    '스포츠', 
    '정치'
  ];

  const contentData = [
    {
      id: 1,
      imageUrl: process.env.PUBLIC_URL + "/img/visual1.png",
      link: "/Product?id=1&visualTypeId=1",
      title: "지도 데이터"
    },
    {
      id: 2,
      imageUrl: process.env.PUBLIC_URL + "/img/visual2.png",
      link: "/Product?id=1&visualTypeId=2",
      title: "원 데이터"
    },
    {
      id: 3,
      imageUrl: process.env.PUBLIC_URL + "/img/visual3.png",
      link: "/Product?id=1&visualTypeId=3",
      title: "선 데이터"
    },
    {
      id: 4,
      imageUrl: process.env.PUBLIC_URL + "/img/visual4.png",
      link: "/Product?id=1&visualTypeId=4",
      title: "막대 데이터"
    },
    {
      id: 5,
      imageUrl: process.env.PUBLIC_URL + "/img/visual5.png",
      link: "/Product?id=1&visualTypeId=5",
      title: "3D 지도 데이터"
    }
  ];

  const [selectedCategory, setSelectedCategory] = useState('카테고리');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div id='visual'>
      <h2>시각화 분석</h2>
      <div className='categoryWrap'>
        <div className='customSelect' onClick={() => setIsDropdownOpen(!isDropdownOpen)} ref={dropdownRef}>
          <div className='selectedOption' style={{ color: selectedCategory === '카테고리' ? '#aaa' : '#222' }}>
            {selectedCategory}
          </div>
          {isDropdownOpen && (
            <ul className='dropdown'>
              {categories.map((category, index) => (
                <li key={index} onClick={() => handleCategorySelect(category)}>
                  {category}
                </li>
              ))}
            </ul>
          )}
        <img className='dropdownImg' src={process.env.PUBLIC_URL + "/img/dropdown.png"} alt="search" />
        </div>
        <div className="searchWrap">
          <input type="text" placeholder="검색어를 입력하세요." />
          <button type='button'>
            <img className='searchBtn' src={process.env.PUBLIC_URL + "/img/searchG.png"} alt="search" />
          </button>
        </div>
      </div>
      <div className='visualWrap'>
        <ul>
          {contentData.map((content) => (
            <li key={content.id}>
              <img src={content.imageUrl} alt={content.title} />
              <div>
                <p>{content.title}</p>
                <Link to={content.link}>자세히보기</Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Visualization;
